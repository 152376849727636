.pwd__input{
    position: relative;
}
.showpwd__button{
    position: absolute;
    top: 5px;
    right: 15px;
}
/* .bg__signup{
    background-image: url('../../../assets/img/background-web-1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */
/* .bg__signin{
    background-image: url('../../../assets/img/logo.png');
} */