.loading_container {
    position: fixed;
    z-index: 1000;
    /* height: 100%;
    width: 100%; */
    /* overflow: show; */
    background: rgba(0, 0, 0, 0.3);
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 3s ease-in-out;
    visibility: visible;
}
.img_container{
    position: relative;
}
.close_btn{
    position: absolute;
    right: 0;
    top: -35px;
    cursor: pointer;
}
.loading_img {
    /* transition: all 3s ease-in-out; */
}